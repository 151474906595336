.email-container {
  color: #fff;
  width: auto;
  margin: auto;
  flex: 0 1 auto;
  padding-bottom: 8vh;
}

.mc__form {
  display: flex;
  flex-direction: column;
}

.mc__title {
  margin-bottom: 4vh;
  font-size: 1.5rem;
}

.mc__field-container {
  display: flex;
  flex-direction: row;
}

.mc__field-container input {
  color: #fff;
  padding: 8px 20px;
  background: none;
  width: 80%;
  height: 7vh;
  border-radius: 2px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border-bottom: 1px solid #fff;
}

.mc__field-container input::placeholder {
  color: white;
}

.mc__field-container button {
  color: #fff;
  padding: 8px 20px;
  background: none;
  flex: 0 1 auto;
  width: 20%;
  height: 7vh;
  border-radius: 2px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
  margin-left: 2vw;
}

.mc__field-container button:hover {
  color: #000;
  background-color: #8983c4;
  border-color: #8983c4;
}

@media screen and (max-width: 1440px) {
  .email-container {
    max-width: 60vw;
  }
}

@media screen and (max-width: 820px) {
  .email-container {
    max-width: 80vw;
    padding-bottom: 0;
    flex: 0;
    margin: 2vh auto
  }
  .email-container form {
    flex-direction: column;
  }

  .email-container button {
    height: 80%;
    margin-left: 0;
  }

  .mc__field-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mc__field-container input {
    width: 100%;
  }

  .mc__field-container button {
    width: 100%;
  }

  .mc__title {
    font-size: 1.1rem;
    margin-bottom: 2vh;
  }
}
