video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  height: 87vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.headline {
  color: #fff;
  font-size: 3rem;
  display: flex;
  flex-direction: row;
  margin: 20vh auto 0;
}

.headline h1 {
  width: 85vw;
  text-align: center;
}

.hero-container > p {
  margin: 8px auto 0;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-align: center;
}

@media screen and (max-width: 1440px) {
  video {
    object-fit:stretch;
    min-height: 60rem;
  }

  .hero-container {
    height: 50rem;
  }

  .headline {
    margin: 0 auto;
  }

  .headline h1 {
    max-width: 65vw;
    margin-bottom: 2rem;
  }

  .hero-container p {
    max-width: 80vw;
    margin-bottom: 4vh;
  }
}

@media screen and (max-width: 768px) {
  .headline {
    margin-top: 4vh;
  }
  .headline h1 {
    font-size: 3rem;
  }
  .hero-container {
    height: 100vh;
  }

  .hero-container > p {
    font-size: 1.3rem;
  }
}
