.process-section {
  background: #8983c4 url('../../public/images/hexbg1.png') center/cover
    no-repeat;
  display: flex;
  flex-direction: column;
}

.process {
  display: flex;
  margin: 1rem 4rem;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card-container {
  padding: 2vw;
  margin: 1vw;
  flex: 0 1 400px;
}

.img-wrapper {
  border: solid white 3px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.process-section img {
  width: 100%;
}

.process-section ul {
  list-style-position: inside;
  font-size: 1.1rem;
}

.process-section h1 {
  font-family: 'Dancing Script', cursive;
  margin-top: 3rem;
  padding-left: 8vw;
  font-size: 6rem;
}
.process-section h2 {
  margin-bottom: 1rem;
  font-size: 2.2rem;
}

@media screen and (max-width: 820px) {
  .process-section h1 {
    font-size: 3.5rem;
    text-align: center;
    padding-left: 0;
  }
  .process {
    max-width: 80vw;
  }
  .card-container {
    flex: 1 0 300px;
  }
  .card-item h2 {
    text-align: center;
  }
  .card-item ul {
    font-size: 1rem;
  }
}
