.services-section {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-top: 2rem;
}

.services-section h1 {
  font-size: 4rem;
  margin-left: 10vw;
  padding-top: 3rem;
}

.services-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.services-wrapper {
  padding: 2vw;
  margin: 1vw;
  flex: 0 1 500px;
}

.services-wrapper h2 {
  padding: 2rem;
  font-size: 2rem;
  text-align: center;
}

.services-section i {
  padding-bottom: 2rem;
  text-align: center;
  font-size: 3rem;
}

.services-wrapper li {
  margin-top: 0.7rem;
}

.services-wrapper ul {
  text-align: left;
  list-style-position: inside;
  font-size: 1.1rem;
  padding-top: 2rem;
  /* height: 100%; */
}

.services-image {
  width: 100%;
  height: 50vh;
  overflow: hidden;
}

.services-image img {
  width: 100%;
  height: 100%;
  border: solid 3px white;
  border-radius: 20px;
  object-fit: cover;
}

.hidden-div {
  height: 0px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.expanded-div {
  display: flex;
  flex-direction: column;
  height: 16vh;
  transition: height 0.3s ease-in-out;
  width: 100%;
}

.show-list {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s, opacity 0.5s linear;
}

.hide-list {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.book-btn {
  font-size: 1.4rem;
  text-align: center;
  width: 20vw;
  height: 6vh;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1vh;
  border: solid 1px black;
  border-radius: 5px;
}

.book-btn:hover {
  color: #fff;
  background-color: #000;
  border: solid 1px #fff;
  transition: all ease-in 0.1s;
}

@media screen and (max-width: 820px) {
  .services-section h1 {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .services-container {
    max-width: 80vw;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    flex-wrap: nowrap;
  }
  .services-wrapper {
    width: 100%;
    flex: 0 0 0;
    margin: 0;
  }
  .services-wrapper h2 {
    font-size: 1.8rem;
  }
  .services-image {
    height: 35vh;
  }
  .expanded-div {
    height: 20vh;
  }
  .book-btn {
    width: 70vw;
    color: #000;
  }
}
