.about-me__container {
  background: #8983c4 url('../../public/images/hexbg2.jpeg') center/cover
    no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.about-me__img-container {
  margin-top: auto;
  margin-right: auto;
  height: 90%;
  width: 100%;
}

.about-me__img-container img {
  float: right;
  width: auto;
  height: 80vh;
}

.about-me__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding-left: 7vw;
  width: 80%;
}

.about-me__headline{
  font-size: 3rem;
  margin: 2rem 0;
}
.about-me__wrapper p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 820px) {
  .about-me__container {
    justify-content: center;
    text-align: left;
  }

  .about-me__wrapper {
    padding: 0;
    width: 85vw
  }

  .about-me__img-container {
    display: none;
  }
  .about-me__headline {
    font-size: 2rem;
  }
  .about-me__wrapper p {  }
}
