.cards {
  max-width: 200px;
  margin: auto;
  text-align: center;
}

.cards img {
  width: 100%;
  height: auto;
  object-fit:fill;
}
