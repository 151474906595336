.footer-container {
  background-color: #000;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 4rem 0;
}

.footer-logo-text {
  padding-left: 1vw;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
}
.social-icon-link {
  color: #8983c4;
  font-size: 24px;
  margin: auto;
}

.social-media {
  width: auto;
  height: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10vh;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 6vw;
}

.social-icon-link:hover {
  transition: 0.2s ease-in-out;
  transform: scale(0.8, 0.8);
}

.social-logo {
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  height: 100%;
}

.website-rights {
  color: #fff;
  margin-top: 3rem;
}

@media screen and (max-width: 820px) {
  .footer-container {
    position: absolute;
    width: 100vw;
    height: auto;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .email-container h2 {
    font-size: 3.2rem;
  }

  .social-media-wrap {
    flex-direction: column;
    width: auto;
    height: 100%;
  }

  .social-icons {
    padding: 2rem 0;
    width: 30vw;
  }

  .social-icons a:hover {
    color: white;
    transition: 0.3s ease-out;
    transform: none;
  }

  .social-icon-link i {
    margin: auto;
  }

  .social-logo {
    flex-direction: column;
    text-align: center;
    width: 80vw;
  }

  .social-logo img {
    width: 22vw;
  }

  .website-rights {
    margin: 0;
  }

  .footer-logo-text {
    padding: 0;
  }

}
