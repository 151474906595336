.banner-container {
  background-color: #8983c4;
  color: #000;
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  height: 4vh;
  z-index: 999;
}

.banner-container p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 4vh;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.navbar-logo {
  height: 100px;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-content: center;
  padding-left: 10px;
}

.navbar-logo:active {
  color: #8983c4;
}

.logo-text {
  margin: auto;
  padding-left: 1rem;
}

.logo-top {
  color: #fff;
}

.logo-bottom {
  color: #8983c4;
}

img {
  height: 100%;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
  color: #fff;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  width: auto;
  height: 100%;
  justify-content: flex-end;
  margin-right: 2rem;
}

.nav-item {
  height: 100%;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #8983c4;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .banner-container {
    font-size: 85%;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #8983c4;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding-left: 10px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 0;
    padding-right: 4vw;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #8983c4;
    border-color: #8983c4;
    color: #242424;
    transition: 250ms;
  }
}
